Drupal.behaviors.header = {
  attach(context) {
    const headerClass = '.region-header-menu';
    const fixedClass = 'header-fixed';
    jQuery(window, context).scroll(function() {
      var scroll = jQuery(window).scrollTop();
      if (scroll > 60) {
        jQuery(headerClass).addClass(fixedClass);
      } else {
        jQuery(headerClass).removeClass(fixedClass);
      }
    }).once('header');
  },
};
