import { config, dom, library } from '@fortawesome/fontawesome-svg-core';

// To import new icons: https://fontawesome.com/how-to-use/javascript-api/setup/importing-icons
import { faFacebookF, faLinkedinIn, faTwitter } from '@fortawesome/free-brands-svg-icons';
import {
  faArrowLeft, faArrowRight, faBars, faTimes, faMobileAlt, faFax, faPenSquare, faFile, faTh, faMapMarkerAlt, faAngleDoubleRight, faAngleRight, faAngleDown, faAngleDoubleLeft, faAngleLeft, faArrowDown, faChevronDown, faChevronRight, faCopyright, faShareAlt, faBell, faPrint, faPlusCircle, faCheckCircle, faClock, faInfo, faPencilAlt, faUser
} from '@fortawesome/free-solid-svg-icons';

import {
  faHeart
} from '@fortawesome/free-regular-svg-icons';


config.autoReplaceSvg = true;
config.observeMutations = true;
config.searchPseudoElements = true;

library.add(faFacebookF, faLinkedinIn, faTwitter, faArrowLeft,
  faArrowRight, faBars, faTimes, faMobileAlt, faFax, faPenSquare, faFile, faTh, faMapMarkerAlt, faHeart, faAngleRight, faAngleDoubleRight, faAngleDown, faAngleDoubleLeft, faAngleLeft, faArrowDown, faChevronDown, faChevronRight, faCopyright, faShareAlt, faBell, faPrint, faPlusCircle, faCheckCircle, faClock, faInfo, faPencilAlt, faUser);

dom.watch();
