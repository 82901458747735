"use strict";

Drupal.behaviors.SideMenu = {
  attach: function (context) {
    const panel = jQuery('#sidr', context).once('SideMenu');
    if (panel.length) {
      jQuery('.page__header .menu-header:not(.menu-header--bis)', context).clone().prependTo('#sidr nav').once('SideMenu');
      jQuery('#mobile-menu', context).sidr({
        'name': 'sidr',
        'side': 'right',
        'source': null,
        'renaming': true,
        'body': 'html',
        'displace': false,
        'timing': 'ease',
        'method': 'toggle',
        'bind': 'touchstart click'
      }).once('SideMenu');
      jQuery("#sidr nav .active", context).removeClass("active").once('SideMenu');
      jQuery('#sidr nav', context).slinky();
    }
  }
}
