import Swiper from 'swiper/bundle';

Drupal.behaviors.swiper = {
  attach(context, settings) {

    Drupal.swiper.init();
  },
  detach(context, settings, trigger) {
    // Nothing yet...
  }
};

Drupal.swiper = {
  init: function () {
    this.bannerSlider();
    this.immobilierMainSlider();
    this.immobilierThumbs();
    this.imagesSliderSelection();
    this.imagesSliderNews();
    this.panelsSlider();
    this.frontNewsSlider();
    this.frontTeamSlider();
  },
  bannerSlider: function () {
    if (document.querySelector('.home-swiper-container')) {
      const bannerSlider = new Swiper('.home-swiper-container', {
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
        },
        speed: 600,
        loop: true,
        effect: 'fade',
        fadeEffect: {
          crossFade: true
        },
        pagination: {
          el: '.home-swiper-container .swiper-pagination',
          clickable: true,
          type: 'bullets',
        }
      });
      return bannerSlider;
    }
  },
  immobilierMainSlider: function () {
    if (document.querySelector('.immobilier-main-slider')) {
      const galleryThumbs = this.immobilierThumbs();

      const immobilierMainSlider = new Swiper('.immobilier-main-slider', {

        loop: false,
        loopedSlides: 1, //looped slides should be the same
        spaceBetween: 0,
        slidesPerView: 1,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        thumbs: {
          swiper: galleryThumbs,
        },
        on : {
          slideNextTransitionEnd: function() {
            galleryThumbs.slideNext();

          },
          slidePrevTransitionEnd: function() {
            galleryThumbs.slidePrev();

          }
        }
      });

      return immobilierMainSlider;

    }
  },
  immobilierThumbs: function () {
    if (document.querySelector('.gallery-thumbs')) {
      const galleryThumbs = new Swiper('.gallery-thumbs', {
        spaceBetween: 15,
        slidesPerView: 2,
        direction: 'vertical',
        loop: false,
        autoHeight: true,
        loopedSlides: 1, //looped slides should be the same
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },

        breakpoints: {
          0: {
            spaceBetween: 10,
            direction: 'horizontal',
            slidesPerView: 2,
          },
          1024: {
            spaceBetween: 10,
            direction: 'horizontal',
            slidesPerView: 4,
          },
          1245: {
            spaceBetween: 15,
            direction: 'vertical',
            slidesPerView: 2,
          },
        }

      });

      return galleryThumbs;
    }
  },
  imagesSliderSelection: function () {
    if (document.querySelector('.js-front-slider-selection')) {
      const imagesSliderSelection = new Swiper('.js-front-slider-selection', {
        // autoplay: {
        //   delay: 4000,
        //   disableOnInteraction: false,
        // },

        freeMode: false,
        loop: true,
        pagination: {
          el: '.js-front-slider-selection .swiper-pagination',
          dynamicBullets: false,
          clickable: true,
        },
        breakpoints: {
          640: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 40,
          },
          991: {
            slidesPerView: 3,
            spaceBetween: 30,
          }
        }

      });
      return imagesSliderSelection;
    }
  },
  imagesSliderNews: function () {
    if (document.querySelector('.swiper-container.news')) {
      const imagesSliderNews = new Swiper('.swiper-container.news', {
        loop: false,
        pagination: {
          el: '.swiper-container.news .swiper-pagination',
          dynamicBullets: false,
          clickable: true,
        },

      });
      return imagesSliderNews;
    }
  },
  panelsSlider: function() {
    if (document.querySelector('.swiper-panels-container')) {
      const bannerSlider = new Swiper('.swiper-panels-container', {
        speed: 800,
        spaceBetween: 10,
        slidesPerView: 1,
        centerInsufficientSlides: true,
        breakpoints: {
          460: {
            slidesPerView: 1,
            spaceBetween: 10
          },
          576: {
            slidesPerView: 2,
            spaceBetween: 10
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 20
          },
          1024: {
            slidesPerView: 4,
            spaceBetween: 20
          }
        },
        pagination: {
          el: '.swiper-panels-container .swiper-pagination',
          clickable: true,
          type: 'bullets',
        }
      });
      return bannerSlider;
    }
  },
  frontNewsSlider: function() {
    if (document.querySelector('.news-swiper-container')) {
      const bannerSlider = new Swiper('.news-swiper-container', {
        speed: 800,
        slidesPerView: 1,
        direction: 'horizontal',
        spaceBetween: 20,
        allowTouchMove: true,
        breakpoints: {
          1024: {
            slidesPerView: 3,
            direction: 'vertical',
            allowTouchMove: false
          }
        },
        pagination: {
          el: '.news-swiper-container .swiper-pagination',
          clickable: true,
          type: 'bullets',
        }
      });
      return bannerSlider;
    }
  },
  frontTeamSlider: function() {
    if (document.querySelector('.team-swiper-container')) {
      var firstSlide = jQuery('.block-home-team-block .member').length;
      const bannerSlider = new Swiper('.team-swiper-container', {
        speed: 800,
        slidesPerView: 2,
        spaceBetween: 30,
        initialSlide: firstSlide,
        navigation: {
          nextEl: '.block-home-team-block .swiper-navigation-button-next',
          prevEl: '.block-home-team-block .swiper-navigation-button-prev',
        },
        breakpoints: {
          768: {
            slidesPerView: 3,
            spaceBetween: 30
          },
          1024: {
            slidesPerView: 2,
            spaceBetween: 30
          }
        }
      });
      return bannerSlider;
    }
  },

}

var swiperReviews = new Swiper('.slider-reviews', {
  slidesPerView: 1,
  spaceBetween: 0,
  loop: true,
  paginationClickable: true,
  allowTouchMove: true,
  autoplay: {
    delay: 5000
  },
  pagination: {
    el: '.views-reviews-front .swiper-pagination',
    clickable: true,
    type: 'bullets',
  },
});
