import './libraries/font-awesome';
import './fonts-loader';
import "jquery-slinky/src/slinky";
import "sidr/src/jquery.sidr";
import select2 from 'select2';
import './behaviors/Swiper';
import "@fancyapps/fancybox";
import './behaviors/sideMenu';
//import './behaviors/cookies';
import './behaviors/header';


jQuery(document).ready(function () {
  jQuery('select').select2({ "width": "100%" });
  jQuery('.form-item-sort select').select2({
    minimumResultsForSearch: -1
  });
  jQuery('.select2-selection__arrow').html('<i class="fa fa-angle-down"></i>');

  jQuery('.js-fancybox-media').fancybox({
    openEffect: 'none',
    closeEffect: 'none',
    helpers: {
      media: {}
    }
  });

  jQuery(".js-informations, .js-partager").fancybox({
    title: "",
    maxWidth: 500,
    padding: [30, 30, 30, 30],
    scrolling: "no"
  });

  jQuery(document).ajaxComplete(function () { $.fancybox.update(); });
  jQuery(".js-alert-mail").fancybox({
    title: "",
    padding: [30, 30, 30, 30],
    scrolling: "no"
  });

  if (jQuery('.js-arrow-down')) {
    jQuery('.js-arrow-down').click(function (e) {
      e.preventDefault();
      jQuery('html,body').animate({
        scrollTop: jQuery('.page-title').offset().top - 100
      }, 'slow');
    });
  }
});
